var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-card',[_c('v-toolbar',{staticClass:"transparent pt-2",attrs:{"flat":""}},[(_vm.currentUser.tipo === 'V')?_c('v-btn',{staticClass:"white--text",attrs:{"color":"green"},on:{"click":function($event){return _vm.novaVenda()}}},[_vm._v("Nova venda")]):_vm._e(),(_vm.currentUser.tipo === 'V')?_c('v-spacer'):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.date,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"persistent-hint":"","prepend-icon":"event","value":_vm.computedDataInicial,"label":"Data Inicial","readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"locale":"pt-br","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.filtros.data_inicial),callback:function ($$v) {_vm.$set(_vm.filtros, "data_inicial", $$v)},expression:"filtros.data_inicial"}})],1),_c('v-spacer'),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.date2,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"persistent-hint":"","prepend-icon":"event","value":_vm.computedDataFinal,"label":"Data Final","readonly":""}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{ref:"picker",attrs:{"locale":"pt-br","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.filtros.data_final),callback:function ($$v) {_vm.$set(_vm.filtros, "data_final", $$v)},expression:"filtros.data_final"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","rows-per-page-items":[5,10,20,50,100],"dense":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.codigo_abreviado))]),_c('td',[_vm._v(_vm._s(props.item.cliente_nome))]),_c('td',[_vm._v(_vm._s(props.item.cnpj_cpf_format))]),_c('td',[_vm._v(_vm._s(props.item.cliente_email))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(props.item.valor_total)))]),_c('td',[_vm._v(_vm._s(props.item.data_cadastro_format))]),(props.item.situacao === 'ED')?_c('td',[_vm._v("Em digitação")]):_vm._e(),(props.item.situacao === 'AP')?_c('td',[_vm._v("Aguardando pagamento")]):_vm._e(),(props.item.situacao === 'PA')?_c('td',[_vm._v("Pago")]):_vm._e(),(props.item.situacao === 'AU')?_c('td',[_vm._v("Autorizado")]):_vm._e(),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 td-cursor",attrs:{"slot":"activator","small":"","color":"primary"},on:{"click":function($event){return _vm.editVenda(props.item)}},slot:"activator"},on),[_vm._v("remove_red_eye")])]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.autoriza_pag && props.item.situacao === 'AP' && _vm.currentUser.tipo === 'L'))?_c('v-icon',_vm._g({staticClass:"mr-2 td-cursor",attrs:{"slot":"activator","small":"","color":"green"},on:{"click":function($event){return _vm.autorizar(props.item)}},slot:"activator"},on),[_vm._v("done_all")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Autorizar geração de contrato")])])],1)])]}},{key:"no-results",fn:function(){return [_c('div',{staticClass:"text-xs-center"},[_vm._v("Sem resultados para a pesquisa \""+_vm._s(_vm.search)+"\".")])]},proxy:true},{key:"footer",fn:function(){return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"text-xs-right"},[_c('b',[_vm._v("Total R$ "+_vm._s(_vm._f("currency")(_vm.computedTotalVenda)))])])])]},proxy:true}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"text-xs-center"},[_vm._v("Sem registros.")])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
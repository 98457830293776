<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <!-- PESQUISA -->
        <v-toolbar flat class="transparent pt-2">
          <v-btn
            v-if="currentUser.tipo === 'V'"
            color="green"
            class="white--text"
            @click="novaVenda()"
          >Nova venda</v-btn>
          <v-spacer v-if="currentUser.tipo === 'V'"></v-spacer>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="date"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                persistent-hint
                prepend-icon="event"
                :value="computedDataInicial"
                label="Data Inicial"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="filtros.data_inicial"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="date2"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                persistent-hint
                prepend-icon="event"
                :value="computedDataFinal"
                label="Data Final"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="filtros.data_final"
              locale="pt-br"
              no-title
              scrollable
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            color="primary"
          ></v-text-field>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          item-key="id"
          :rows-per-page-items="[5,10,20,50,100]"
          dense
        >
          <template slot="no-data">
            <div class="text-xs-center">Sem registros.</div>
          </template>
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.codigo_abreviado }}</td>
              <td>{{ props.item.cliente_nome }}</td>
              <td>{{ props.item.cnpj_cpf_format }}</td>
              <td>{{ props.item.cliente_email}}</td>
              <td>{{ props.item.valor_total | currency}}</td>
              <td>{{ props.item.data_cadastro_format }}</td>
              <td v-if="props.item.situacao === 'ED'">Em digitação</td>
              <td v-if="props.item.situacao === 'AP'">Aguardando pagamento</td>
              <td v-if="props.item.situacao === 'PA'">Pago</td>
              <td v-if="props.item.situacao === 'AU'">Autorizado</td>
              <td class="text-xs-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small
                      class="mr-2 td-cursor"
                      slot="activator"
                      @click="editVenda(props.item)"
                      color="primary"
                    >remove_red_eye</v-icon>
                  </template>
                  <span>Ver detalhes</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="(autoriza_pag && props.item.situacao === 'AP' && currentUser.tipo === 'L')"
                      small
                      class="mr-2 td-cursor"
                      slot="activator"
                      @click="autorizar(props.item)"
                      color="green"
                    >done_all</v-icon>
                  </template>
                  <span>Autorizar geração de contrato</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
          </template>
          <template v-slot:footer>
            <td :colspan="headers.length">
              <div class="text-xs-right">
                <b>Total R$ {{computedTotalVenda | currency}}</b>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as moment from 'moment'
import { mapGetters } from 'vuex'
const consultaModule = () => import('./../../services/consulta')
const faturamentoModule = () => import('./../../services/faturamento')

export default {
  name: 'Contratos-List',
  props: ['autoriza_pag'],
  data () {
    return {
      search: '',
      headers: [
        { text: 'Contrato', align: 'left', value: 'codigo_abreviado' },
        { text: 'Cliente', align: 'left', value: 'cliente_nome' },
        { text: 'Documento', align: 'left', value: 'cnpj_cpf_format' },
        { text: 'E-mail', align: 'left', value: 'cliente_email' },
        { text: 'Valor(R$)', align: 'left', value: 'valor_total' },
        { text: 'Data de cadastro', align: 'left', value: 'data_cadastro_format' },
        { text: 'Situação', align: 'left', value: 'situacao' },
        { text: 'Opções', align: 'left', value: '' },
        // { text: 'Usuários', align: 'left', value: '' },
      ],
      items: [],
      date: new Date().toISOString().substr(0, 7),
      date2: new Date().toISOString().substr(0, 7),
      menu: false,
      menu2: false,
      filtros: {
        data_inicial: new Date().toISOString().substr(0, 10),
        data_final: new Date().toISOString().substr(0, 10),
        unidade_codigo: 0,
        situacao: '0'
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    computedDataInicial () {
      return this.filtros.data_inicial ? moment(this.filtros.data_inicial).format('DD/MM/YYYY') : ''
    },
    computedDataFinal () {
      return this.filtros.data_final ? moment(this.filtros.data_final).format('DD/MM/YYYY') : ''
    },
    computedTotalVenda () {
      if (this.items.length > 0) {
        return this.items
          .filter(e => parseFloat(e.valor_total) > 0)
          .map(element => parseFloat(element.valor_total))
          .reduce((total, valor) => {
            return total += parseFloat(valor)
          })
      } else {
        return 0
      }
    }
  },
  mounted () {
    // this.getAll()
    this.getConsulta()
  },
  watch: {
    'filtros.data_inicial': function () {
      // this.getAll()
      this.getConsulta()
    },
    'filtros.data_final': function () {
      // this.getAll()
      this.getConsulta()
    }
  },
  methods: {
    novaVenda () {
      this.$router.push('/vendas/detalhes')
    },
    editVenda (item) {
      this.$router.push(`/vendas/detalhes/${item.codigo}`)
    },
    async getConsulta () {
      let consultaService = await consultaModule()
      let resp = await consultaService.getContratos(this.$axios, { ...this.filtros })
      if (resp.status === 200 && resp.data) {
        this.items = resp.data
      }
    },
    async autorizar (venda) {
      let confirm = await this.$swal({
        title: 'Atenção',
        text: `Deseja realmente autorizar?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não'
      })
      if (confirm.value) {
        let faturamentoService = await faturamentoModule()
        let resp = await faturamentoService.autorizarContrato(this.$axios, { codigo: venda.codigo })
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao autorizar o registro.')
          this.getConsulta()
        } else {
          this.$swal('Não foi possível autorizar o registro.', '', 'error')
        }
      }

    }
  }
}
</script>